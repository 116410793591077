<!-- eslint-disable no-restricted-syntax -->

<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        indicators
        background=""
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <img
          v-if="$vuetify.breakpoint.lgAndUp"
          src="@/assets/images/anlas-header.png"
          width="920"
          height="120"
          class="top-image"
        />

        <img
          v-if="$vuetify.breakpoint.mdAndDown"
          src="@/assets/images/logos/anlas-logo.png"
          width="100%"
          height="100%"
          alt="logo"
          class="grid-center"
        />

        <div v-else>
          <b-carousel-slide
            v-for="(item, index) in randomizedSlides"
            :key="index"
            :img-src="item.imgSrc"
            class=""
          ></b-carousel-slide>
        </div>
      </b-carousel>

      <div class="transparent">
        <div xl="3" lg="4" md="5" class="center" :style="!isDark ? 'background-color:#efeef0' : null">
          <!--/ brand logo
          <v-card flat color="transparent ">
            <img src="@/assets/images/logos/anlas-logo.png" width="100%" alt="logo" class="mb-0 mb-md-16" />
             -->

          <!-- login form -->
          <v-card-text class="custom-card-text">
            <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
              <v-text-field
                v-model="username"
                filled
                :background-color="isDark ? '#373351' : 'white'"
                class="mb-3 overflow-hidden"
                label="Kullanıcı Adı"
                hide-details="auto"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Şifre"
                :background-color="isDark ? '#373351' : 'white'"
                autocomplete="new-password"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details="auto"
                filled
                class="mb-6 overflow-hidden"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>

              <div class="d-flex align-center justify-space-between flex-wrap">
                <!-- forget link -->
                <router-link
                  :to="{ name: 'auth-forgot-password' }"
                  class="ms-3 font-weight-medium ls-n1"
                  :class="isDark ? 'white--text' : 'primary--text'"
                >
                  Şifremi Unuttum?
                </router-link>
                <v-btn
                  color="#000000"
                  type="submit"
                  class="text-capitalize text-body-2 white--text"
                  x-large
                  tile
                  :loading="loading"
                >
                  Giriş Yap
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogForm" :overlay-opacity="0.9" persistent max-width="600">
      <v-card color="nightDark">
        <v-card-title>
          <v-icon left large>
            {{ icons.mdiDraw }}
          </v-icon>
          Sözleşmeler
          <v-spacer />
          <v-btn icon @click="dialogClose()">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-stepper v-model="e6" vertical class="pb-0">
            <v-stepper-step :complete="e6 > 1" step="1">
              <div class="d-flex align-center">
                <v-icon v-if="e6 > 1" color="secondary" left>
                  {{ icons.mdiCheckBold }}
                </v-icon>
                <h3 class="font-weight-medium" :class="e6 === 1 ? 'text-h5' : 'text-body-2'">
                  Kullanıcı ve Üyelik Sözleşmesi
                </h3>
              </div>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2">
                <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                  <v-card-text class="pa-0 pr-5 black--text">
                    <div class="text-body-1 font-weight-bold pb-3">MADDE 1 - SÖZLEŞMENİN TARAFLARI</div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Kullanıcı ve Üyelik Sözleşmesi (Bundan sonra “Sözleşme” olarak anılacaktır.) bir tarafta
                      Eksun Gıda Tarım ve Sanayi A.Ş. (Bundan sonra “Şirket” olarak anılacaktır.) ile bayi.eksun.com.tr
                      (Bundan sonra “Web Sitesi” olarak anılacaktır.) adresine kaydolarak işlem yapan Üye (Web Sitesi’ne
                      üye olan Kullanıcı’yı ifade eder.) ve/veya Kullanıcı (Web Sitesi’ni ziyaret eden kişiyi ifade
                      eder.) arasında aşağıdaki şartlar dâhilinde akdedilmiştir.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Sözleşme’de Şirket ile Üye münferiden “Taraf” birlikte “Taraflar” olarak anılacaktır.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Sözleşme’de Üye için getirilen hükümler Kullanıcı için de aynen geçerlidir.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">MADDE 2 - SÖZLEŞMENİN KONUSU VE AMACI</div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Sözleşme, Şirket’in oluşturduğu ve işlettiği bayi.eksun.com.tr (Bundan sonra “Web Sitesi”
                      olarak anılacaktır.) adresli web sitesinin Üye tarafından işbu Sözleşme hükümlerine uygun olarak
                      kullanılması amacıyla düzenlenmiştir. Web Sitesi, Şirket’le sözleşme yapan üyelerin ve
                      kullanıcıların çeşitli usullerle mal alım satımı yapmak maksadıyla anlaşmaya varabilecekleri
                      internet üzerinden ulaşılan elektronik bir ortamdır.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">MADDE 3 - KULLANMA VE KABUL</div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Sözleşme, Üye’nin işbu Web Sitesi’ni kullanması durumunda Taraflar arasında kendiliğinde
                      hüküm ifade edecektir. Üye işbu Sözleşme şartlarını okuyup anladığını ve kabul ettiğini gayri
                      kabili rücu kabul, beyan ve taahhüt eder.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">MADDE 4 – SÖZLEŞME HÜKÜMLERİ</div>
                    <div class="text-caption text-justify pb-3">
                      Üye, işbu Sözleşme’yi ve/veya Web Sitesi nezdinde taraf olacağı diğer sözleşmeleri imzalamaya ve
                      işbu Sözleşme’den ve/veya Web Sitesi nezdinde taraf olacağı diğer sözleşmelerden kaynaklanan
                      borçlarını ifaya hukuken yetkili olduğunu, Şirket’e verdiği tüm bilgi ve belgelerin doğru ve
                      verildikleri anda geçerli olduğunu, bunlarda meydana gelecek ve işbu Sözleşme üzerinde etkisi
                      olacak her türlü değişikliği derhal Şirket’e bildireceğini beyan ve taahhüt eder.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye, Web Sitesi’ne, kendi adına girilecek tüm bilgilerin doğru olacağını, yanlış, yanıltıcı ve
                      eksik bilgi girmeyeceğini, yetkisi dışında bulunan veya yerine getirme gücü olmayan işlemlere
                      tevessül etmeyeceğini, bu tür teklif ve kabullerde bulunmayacağını ve yaptığı her işlemde dürüst,
                      iyi niyetli ve tedbirli ve basiretli bir tacir gibi davranacağını, Şirket’in ve diğer üyelerin
                      haklarına riayet edeceğini, sistemi kullanırken, sistemin işleyişini engelleyici veya zorlaştırıcı
                      şekilde davranmayacağını beyan, kabul ve taahhüt eder.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye; gönderme, ilan etme, bir link yükleme yoluyla:
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Suç unsuru oluşturmayacağını, kamuyu engellemeyeceğini ve uygulamada olan herhangi bir yasayı
                      çiğnemeyeceğini;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Virüs, bozulmuş dosya, Truva atı (Trojan horse), kurt, iptal programcığı, "fare kapanı” (mouse
                      trap) adı verilen birçok pencere açılmasını sağlayarak siteden çıkılmasını engeleyen girişim ya da
                      yazılım gibi bir başkasının bilgisayarının işlevini engelleyici yazılım ve girişimlerde
                      bulunmayacağını;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Herhangi bir şahsın mahremiyet hakkına tecavüz edici yanlış, yanıltıcı, onur kırıcı, iftira atıcı,
                      leke sürücü, müstehcen, kaba ya da saldırgan girişimde bulunmayacağını;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üçüncü tarafların dünya çapındaki telif hakkı, tescilli marka, patent ve diğer entellektüel
                      haklarını ihlal etmeyeceğini
                    </div>
                    <div class="text-caption text-justify pb-3">kabul ve taahhüt eder.</div>
                    <div class="text-caption text-justify pb-3">
                      Üye, Web Sitesinde ya da site içi ve dışındaki diğer iletişim araçları ile ismi saklı alıcı ya da
                      satıcının gerçek kimliğini meydana çıkarmayacağını beyan, kabul ve taahhüt eder.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye bütün teklifler ve ihalelerin gerçek satış ya da satın alma için yapıldığını, fiyat belirleme
                      ya da sabitleme amaçlı olarak diğer üyelerle fiyat bilgisi ya da ürün bilgisi istihbaratını
                      paylaşmak için yapılmadığını, planlı bir kartel oluşturmak için yapılmadığını, ürünün
                      satılabileceği ya da satıldığı yerdeki anitröst ya da haksız rekabetle ilgili yasalara karşı
                      gelmeyeceğini beyan, kabul ve taahhüt eder.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye Web Sitesi aracılığıyla yapılan her ticaret ve işlemin Türk yasalarına, üyenin ikamet ettiği
                      ülke yasalarına, ticaret yaptığı ya da malın transit geçtiği ülke ya da ülkelerin yargılama
                      yetkisi olan yasalarına ve uygulanabilir tüm hukuki sistemlerinin kurallarına uygun bir şekilde
                      gerçekleştirileceğini kabul ve taahhüt eder.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye, Kullanıcı Adı ve Kullanıcı Şifrelerinin ve Yetki Kodunun yetkili olmayan kişiler tarafından
                      kullanılmasını veya yetki verdiği kişilerin bunları yetkilerini aşacak şekilde kullanmalarını
                      önlemekle ve gerekli denetimleri yapmakla yükümlüdür. Web Sitesi’nde Üye’ye ait Kullanıcı Adı,
                      Kullanıcı Şifresi veya Yetki Kodu ile yapılan her işlem ve her eylem Üye tarafından yapılmış
                      sayılır ve Üye’yi bağlar. Web Sitesi’nde Üye tarafından yapılan veya yapıldığı var sayılan her bir
                      işlem, komut, bilgi girişi ve her türlü elektronik müdahale Üye’ye ait bir irade açıklaması olarak
                      geçerli sayılır ve buna ilişkin hukuki sonuçları doğurur.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye, işbu Sözleşme’den ve Web Sitesi’ni kullanarak yaptığı her türlü işlemden doğan tüm vergi ve
                      masrafları ödeyeceğini beyan, kabul ve taahhüt eder.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Sözleşme Üye’ye, Web Sitesi’ni Sözleşme hükümlerine uygun olarak kullanma hakkı dışında,
                      Şirket’e veya üçüncü kişilere ait fikri mülkiyet hakları veya Web Sitesi’nin içeriği üzerinde veya
                      diğer hiç bir konuda hukuki bir hak vermez. Üye, Şirket’in ve diğer üçüncü kişilerin fikri
                      mülkiyet haklarını ihlal etmemeyi, bu hakları kullanmamayı, kopyalamamayı, tescil ettirmemeyi, bu
                      hakları dolaylı da olsa ihlal etmemeyi beyan, kabul ve taahhüt eder.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye, işbu Sözleşme’nin ifası sırasında, Şirket’le, sistemle ve sisteme dâhil diğer üyelerle ilgili
                      olarak edindiği bilgileri gizli tutmayı ve işbu Sözleşme’nin amaçları dışında kullanmamayı beyan,
                      kabul ve taahhüt eder.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye, Şirket’in açık yazılı muvafakati olmadıkça, işbu Sözleşme’den doğan hak ve yükümlülüklerini
                      kısmen de olsa üçüncü kişilere devredemez.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Şirket, sistemin e-ticaret yapmak üzere kurulmuş olması amacına ters düşmemek üzere, Web
                      Sitesi’nin kullanım amacını, özelliklerini, yapısını, fonksiyonlarını, içeriğini değiştirebilir.
                      Teknik sebeplerden veya üçüncü kişilerin eylem ve işlemlerinden kaynaklanan sorunlar veya zorunlu
                      sebeplerden dolayı da Şirket mesul tutulamaz. Üye’den ek bilgi ve belgeler talep edebilir, önceden
                      Üye’ye haber vermek kaydıyla Web Sitesi’ni iptal edebilir, işletilmesini askıya alabilir.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Şirket, üye sayısını artırmak ve elektronik ticaret sistemini cazip kılmak için veya pazarlama
                      amacıyla Üye hakkında kimliğini açığa vurmamak ve anonimleştirmek kaydıyla bilgi verebilir ve/veya
                      Web Sitesi’nde belirtebilir. Bunun dışında, Şirket, Üye ile ilgili olarak kendisine verilen gizli
                      bilgileri, işbu Sözleşme amacı dışında kullanmayacak, üçüncü kişilere açıklamayacak ve bu
                      bilgileri gizli tutacaktır.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üyenin işbu Sözleşme kapsamında Web Sitesi’ni kullanarak yaptığı işlemlerin cezai ve hukuki tüm
                      sorumluluğu tek başına Üye’ye aittir.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Şirket sadece, kendisine sunulan bilgi ve belgelere göre, sisteme dâhil diğer üyelerin gerçekten
                      var olduğuna kanaat getirdiğini ve bu nedenle bu kişilerin fiktif kişiler olmadığını garanti eder.
                      Şirket, Üyeyle diğer üyeler arasındaki ilişkiler nedeniyle hiç bir şekilde sorumlu tutulamaz.
                    </div>
                    <div class="text-caption text-justify pb-3">Üye, Web Sitesi üzerinde;</div>
                    <div class="text-caption text-justify pb-3">
                      Şirketin kontrolü dışındaki sebeplerden dolayı teknik problemlerin yaşanması;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      İnternet omurgası ya da buna benzer altyapıların Üye’ye siteye giremeyecek şekilde sorun
                      yaşatması;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Şirket tarafından sitenin işlevinin geçici olarak ya da tamamen durdurulması, işlevinde
                      değişiklikler yapılması durumları ile sınırlı kalmaksızın oluşabilecek her türlü kayıp için
                      Şirket’in herhangi bir sorumluluğu ya da yükümlülüğü olmadığını
                    </div>
                    <div class="text-caption text-justify pb-3">kabul ve taahhüt eder.</div>
                    <div class="text-caption text-justify pb-3">
                      Üye, Web Sitesi'ndeki yüklenmeye (download) ve/veya paylaşıma müsait dosya, bilgi ve belgelerin,
                      virüslerden, wormlardan, truva atlarından, dialer programlarından spam, spyware veya bunlar gibi
                      diğer her türlü kötü ve zarar verme amaçlı kodlardan veya materyallerden arındırılamamış
                      olabileceğini ve bu hususlarda Web Sitesi’nin garanti vermediğini kabul eder. Bu tip kötü ve zarar
                      verme amaçlı programların, kodların veya materyallerin önlenmesi, veri giriş - çıkışlarının
                      doğruluğu veya herhangi bir kayıp verinin geri kazanılması için gereken tüm yazılım ve donanım
                      ihtiyaçlarını karşılamak, bakım ve güncellemelerini yapmak tamamen Üye'nin sorumluluğundadır. Bu
                      tür kötü amaçlı programlar, kodlar veya materyallerin sebep olabileceği, veri yanlışlıkları veya
                      kayıplarından dolayı Üye'nin veya üçüncü kişilerin uğrayabileceği hiçbir zarardan Şirket sorumlu
                      değildir. Bu tür kötü amaçlı programlar, kodlar veya materyaller, veri yanlışlıkları veya
                      kayıplarından dolayı Üye'nin veya üçüncü kişilerin uğrayabileceği hiçbir zarardan Şirket sorumlu
                      değildir.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Web Sitesi’nde bulunan bilgiler, yazılar, resimler, markalar, slogan ve diğer işaretler ile
                      sair sınaî ve fikri mülkiyet haklarına ilişkin bilgilerin korunmasına yönelik programlarla, sayfa
                      düzeni ve Web Sitesi'nin sunumu Şirket’in ya da Şirket’in izin ve lisans aldığı kuruluşların
                      mülkiyetindedir. İşbu Web Sitesi’ndeki bilgilerin ya da Web Sitesi sayfalarına ilişkin her tür
                      veri tabanı, web sitesi, software-code'ların html kodu ve diğer kodlar vs. ile Web Sitesi
                      içeriğinde bulunan ürünlerin, tasarımların, resimlerin, metinlerin, görsel, işitsel ve sair
                      imgelerin, video kliplerin, dosyaların, katalogların ve listelerin kısmen ya da tamamen
                      kopyalanması, değiştirilmesi, yayınlanması, online ya da diğer bir medya kullanılmak suretiyle
                      gönderimi, dağıtımı, satılması yasaktır. Üye, yukarıda sayılan ve bunlarla sınırlı olmayan Web
                      Sitesi yazılım, donanım ve içeriğini çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını,
                      işlemeyeceğini, gerek bu eylemleri ile gerekse de başka yollarla Şirket ile doğrudan ve/veya
                      dolaylı olarak rekabete girmeyeceğini kabul eder. Üye; Şirket hizmetlerini, bilgilerini ve
                      Şirket’in telif haklarına tâbi çalışmalarını yeniden satmak, işlemek, paylaşmak, dağıtmak,
                      sergilemek veya başkasının Şirket’in hizmetlerine erişmesi veya kullanmasına izin vermek hakkına
                      sahip değildir. Bu sayfadaki bilgilerin kısmen kopyalanması, basılması, işlenmesi, dağıtılması,
                      çoğaltılması, sergilenmesi ancak ticari olmayan kişisel ihtiyaçlar için ve Şirket’in yazılı izni
                      ile mümkündür.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Şirket, Web Sitesi üzerinden Üye tarafından kendisine iletilen bilgileri işleyebilir, bir veri
                      tabanı üzerinde tasnif edip muhafaza edebilir. Şirket aynı zamanda Üye'nin kimliği, adresi,
                      elektronik posta adresi, telefon numarası, IP adresi, Web Sitesi’nin hangi bölümlerini ziyaret
                      ettiği, domain tipi, browser tipi, ziyaret tarihi, saati vb. bilgileri de istatistiki
                      değerlendirme, kampanyaların duyurusunu yapma ve kişiye yönelik hizmetler sunma gibi amaçlarla
                      kullanabilir. Üye’ye ait kişisel bilgiler, kanunla yetkili kılınan mercilerin talebi ve aşağıda
                      sayılan haller hariç olmak üzere gerçek ve tüzel üçüncü kişilere açıklanmayacaktır. Kişisel bilgi
                      ender olarak Şirket için veya onun adına davranan üçüncü taraflara veya Şirket’in işi ile ilgili
                      olanlara verilerin özgün olarak kullanım amaçlarını daha iyi işlemek ya da Üye’nin önerdiği
                      amaçlara uygun olarak daha iyi hizmet verebilmek üzere verilir.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Şirket, yararlı olacağını düşündüğü haber, ilan, makale ve benzeri hususlara Web Sitesi’nde yer
                      verebilir, diğer web siteleriyle linkler oluşturabilir. Şirket, bunlarda yer alan bilgi ve
                      yorumların doğruluğunu, amaca uygunluğunu, isabetli olmasını garanti etmez. Üye bunları kendi
                      sorumluluğu altında değerlendirir.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Şirket, yasal tebligat yollarının yanı sıra, işbu Sözleşme kapsamında Üye’ye yapacağı bildirim,
                      ihbar ve ihtarları elektronik haberleşme kanallarıyla (e-mail vs.) Üye’ye gönderebilir. Bu şekilde
                      yapılan tebligatlar geçerli bir tebligatın tüm sonuçlarını doğurur.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">MADDE 5 – SÖZLEŞMENİN FESHİ</div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Sözleşme süresiz olarak düzenlenmiştir. Şirket yazılı bir bildirimde bulunarak ve bir süre
                      tayinine gerek olmaksızın önceden bildirmeksizin istediği zaman sözleşmeyi fesih hakkına sahiptir.
                      Ancak, fesih bildiriminin Şirket’e ulaştığı ana kadar doğmuş Şirket alacakları tam olarak ödenir.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">MADDE 6 – ZARAR SORUMLULUĞU</div>
                    <div class="text-caption text-justify pb-3">
                      Üye, Şirketi ve ilgili kurumlarını, çalışanlarını, yöneticilerini, hissedarlarını, ortaklarını,
                      üyelerini ya da çalışanlarını;
                    </div>
                    <div class="text-caption text-justify pb-3">Üyenin işbu Sözleşme’nin şartlarını ihlal etmesi;</div>
                    <div class="text-caption text-justify pb-3">
                      Üyenin Web Sitesi’ni ya da ilgili diğer özelliklerini buna bağlı olarak kullanması;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üyenin katıldığı ve Web Sitesinin kullanıldığı anlaşmalar;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üyenin tamamladığı ya da tamamlayamadığı alım-satım işlemleri;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üyenin telif hakkı, tescilli marka, patent ve diğer entellektüel hakları, gizlilik hakkı ya üçüncü
                      tarafların dünya çapındaki haklarını ihlal etmesi;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      işbu Web Sitesi’nde yer alan sair düzenlemeler ile T.C. mevzuat hükümlerine aykırı hareket etmesi;
                    </div>
                    <div class="text-caption text-justify pb-3">
                      sonucunda oluşabilecek her türlü harcama, iddia, kayıp, zarar ve diğer zararlardan ve makul bir
                      avukat ücretinden Şirket’i muaf tutacağını ve bunları tazmin edeceğini beyan, kabul ve taahhüt
                      eder.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">MADDE 7 - SORUMLULUK KISITLAMASI</div>
                    <div class="text-caption text-justify pb-3">
                      Üye, Şirketi, Şirket’in ilgili kurumlarını, yöneticilerini, direktörlerini, hissedarlarını,
                      ortaklarını, üyelerini ya da çalışanlarını Üyenin ilgili kurumları, yöneticileri, direktörleri,
                      hissedarları, ortakları, üyeleri ya da çalışanları ile diğer üyeler arasında oluşabilecek bilinen
                      ya da bilinmeyen, şüphe duyulan ya da duyulmayan ortaya çıkan her türlü ve tüm anlaşmazlıklardan
                      ve iddialardan muaf tutar.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">
                      MADDE 8 – ELEKTRONİK TİCARETE İLİŞKİN HÜKÜMLER
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Üye, 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve ilgili Yönetmelik kapsamında
                      Şirket tarafından veyahut Şirket’in iş ortakları tarafından, Web Sitesi’nde veyahut Site dışında
                      sunulan ürünler ve hizmetlere ilişkin, telefon, çağrı merkezleri, otomatik arama makineleri,
                      akıllı ses kaydedici sistemler, elektronik posta, kısa mesaj hizmeti gibi vasıtalar kullanılarak
                      elektronik ortamda gerçekleştirilen ve ticari amaçlarla gönderilen veri, ses ve görüntü içerikli
                      her türlü ticari elektronik ileti ile yapılacak tüm tanıtım, kampanya ve bilgilendirme mesajı
                      gönderil faaliyetlerine izin verdiğini, bu konuyla ilgili olarak herhangi bir itirazı olmadığını
                      kabul, beyan ve taahhüt eder.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">
                      MADDE 9 – UYGULANACAK HUKUK VE YETKİLİ MAHKEME
                    </div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Sözleşme Türk Hukukuna tabidir. Taraflar arasında işbu sözleşmeden kaynaklanacak
                      uyuşmazlıklarda İstanbul Anadolu Mahkeme ve İcra Daireleri yetkilidir. Bu uyuşmazlıklarda
                      Şirket’in defter ve kayıtları ve bilgisayar çıktıları kesin delil olacaktır.
                    </div>
                    <div class="text-body-1 font-weight-bold pt-6 pb-3">MADDE 10 – İLAN TARİHİ</div>
                    <div class="text-caption text-justify pb-3">
                      İşbu Sözleşme toplam 10 maddeden müteşekkil olup Web Sitesi’nin kurulmasıyla birlikte ilan
                      edilmiştir.
                    </div>
                  </v-card-text>
                </perfect-scrollbar>
              </v-card>
              <v-card>
                <v-card-actions class="px-0 align-center">
                  <v-checkbox v-model="checkbox1" hide-details dense class="pa-0 ma-0">
                    <template v-slot:label>
                      <div class="text-caption">Kabul Ediyorum</div>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>

                  <v-btn color="secondary" :disabled="!checkbox1" depressed class="text-capitalize" @click="e6 = 2">
                    Devam
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-divider></v-divider>
            <v-stepper-step :complete="e6 > 2" step="2">
              <div class="d-flex align-center">
                <v-icon v-if="e6 > 2" color="secondary" left>
                  {{ icons.mdiCheckBold }}
                </v-icon>
                <h3 class="font-weight-medium" :class="e6 === 2 ? 'text-h5' : 'text-body-2'">KVK Açık Rıza Beyanı</h3>
              </div>
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2">
                <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                  <v-card-text class="pa-0 pr-5 black--text">
                    <div class="text-caption text-justify pb-3">
                      6698 sayılı “Kişisel Verilerin Korunması Kanunu” kapsamında kişisel verilerimin Eksun Gıda Tarım
                      Sanayi ve Ticaret A.Ş. ( “Eksun” veya “Şirket”) tarafından işlenmesine ilişkin olarak kişisel
                      verilerinin veri sorumlusu sıfatıyla Eksun veya gerekli güvenlik tedbirlerini aldırmak suretiyle
                      yetkilendirdiği veri işleyenler tarafından; iletişim faaliyetlerinin yürütülmesi, iş
                      faaliyetlerinin yürütülmesi / denetimi, mal / hizmet satış süreçlerinin yürütülmesi, mal / hizmet
                      üretim ve operasyon süreçlerinin yürütülmesi, organizasyon ve etkinlik yönetimi, yetkili kişi,
                      kurum ve kuruluşlara bilgi verilmesi, yönetim faaliyetlerinin yürütülmesi, pazarlama, reklam ve
                      kampanya faaliyetlerinin yapılması, müşterilerin fırsatlardan, kampanyalardan ve sair hizmetlerden
                      haberdar edilmesi amaçlarıyla doğrudan veya dolaylı olarak ilgili olan kimlik bilgilerinin, adres
                      bilgilerinin, iletişim bilgilerinin ve sair kişisel verilerin; başta mevzuatta öngörülen veya
                      işlendikleri amaç için gerekli olan süre kadar muhafaza edilme ilkesi olmak üzere 6698 Sayılı
                      Kişisel Verilerin Korunması Kanunu’nun (“KVK Kanunu”) 4. maddesinde ifade edilen genel ilkelere
                      uygun şekilde işlenebileceğini; elde edilebileceğini, kaydedilebileceğini, işlenme amacıyla uygun
                      süre zarfında fiziksel veya elektronik ortamda güvenli bir şekilde depolanabileceğini, muhafaza
                      edilebileceğini, değiştirilebileceğini, yeniden düzenlenebileceğini, mevzuata uygun biçimde
                      açıklanabileceğini ve aktarılabileceğini, devralınabileceğini, sınıflandırılabileceğini,
                      işlenebileceğini ya da verilerin kullanılmasının engellenebileceğini; yukarıda belirtilen
                      hususlarla ilgili olarak Şirket tarafından bilgilendirildiğimi ve KVK Kanunu çerçevesinde açık
                      rızam bulunduğunu kabul ve beyan ederim.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      İşbu kişisel verilerimin, yukarıda belirtilen amaçlarla bağlı kalmak kaydıyla, Şirket tarafından;
                      gerçek kişiler veya özel hukuk tüzel kişilere, hissedarlara, iştirakler ve bağlı ortaklıklara,
                      tedarikçilere, topluluk Şirketlerine, yetkili kamu kurum ve kuruluşlarına, Şirket çalışanlarına ve
                      görevlilerine, hukuki zorunluluklar ve yasal sınırlamalar çerçevesinde bağımsız denetim
                      şirketlerine, tarafıma verilecek hizmetlerin ve/veya faaliyetlerin yürütülmesi için Eksun’un
                      hizmet aldığı veya birlikte çalıştığı iş ortaklarına ve hizmet sağlayıcılarına aktarılabileceğini
                      ve bu hususta açık rızam olduğunu kabul ve beyan ederim.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Bununla birlikte, KVK Kanunu’nun 11.maddesi ve ilgili mevzuat uyarınca; Şirkete başvurarak
                      kendimle ilgili; kişisel veri işlenip işlenmediğini öğrenme, kişisel verilerim işlenmişse buna
                      ilişkin bilgi talep etme, kişisel verilerimin işlenme amacını ve bunların amacına uygun kullanılıp
                      kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
                      kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
                      düzeltilmesini isteme, işbu verilerin işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde
                      kişisel verilerimin silinmesini veya yok edilmesini isteme, bu düzeltme ve silinme taleplerinin
                      kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, işlenen verilerin münhasıran
                      otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendi aleyhime bir sonucun ortaya
                      çıkmasına itiraz etme, kişisel verilerimin kanuna aykırı olarak işlenmesi sebebiyle zarara
                      uğraması hâlinde zararın giderilmesini talep etme haklarımın olduğunu ve bu hakları kullanmak için
                      kimliğimi tespit edici gerekli bilgiler ile kullanmayı talep ettiğim hakkıma yönelik açıklamaları
                      da içeren talebimi www.eksun.com.tr adresindeki formu doldurarak ve formun imzalı bir nüshasını
                      Altunizade Mh. Ord. Prof. Fahrettin Kerim Gökay Cd. No:36 Üsküdar/İstanbul adresinde bulunan Eksun
                      İdari İşler Müdürlüğü’ne kimliğimi tespit edici belgeler ile bizzat elden iletme yahut noter
                      kanalıyla veya KVK Kanunu’nda belirtilen diğer yöntemler ile iletme hakkına sahip olduğumu kabul
                      ediyorum.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Ayrıca, Şirket ile paylaşmış olduğum kişisel verilerin doğru ve güncel olduğunu; işbu bilgilerde
                      değişiklik olması halinde değişiklikleri Şirkete bildireceğimi kabul ve beyan ederim.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      İşbu KVK AÇIK RIZA BEYANI’nı okuduğumu ve anladığımı kabul ederim.
                    </div>
                  </v-card-text>
                </perfect-scrollbar>
              </v-card>

              <v-card>
                <v-card-actions class="px-0 align-center">
                  <v-checkbox v-model="checkbox2" hide-details dense class="pa-0 ma-0">
                    <template v-slot:label>
                      <div class="text-caption">Kabul Ediyorum</div>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>

                  <v-btn color="secondary" :disabled="!checkbox2" depressed class="text-capitalize" @click="e6 = 3">
                    Devam
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-divider></v-divider>
            <v-stepper-step :complete="e6 > 3" step="3">
              <div class="d-flex align-center">
                <v-icon v-if="e6 > 3" color="secondary" left>
                  {{ icons.mdiCheckBold }}
                </v-icon>
                <h3 class="font-weight-medium" :class="e6 === 3 ? 'text-h5' : 'text-body-2'">KVK Aydınlatma Metni</h3>
              </div>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2">
                <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                  <v-card-text class="pa-0 pr-5 black--text">
                    <div class="text-body-1 font-weight-bold">AYDINLATMA METNİ</div>
                    <div class="pb-6">(bayi.eksun.com.tr)</div>
                    <div class="font-weight-bold pb-3">Sayın Kullanıcı/Üye,</div>
                    <div class="text-caption text-justify pb-3">
                      Eksun Gıda Tarım Sanayi ve Ticaret A.Ş. olarak kişisel verilerin korunması konusunda gerekli özeni
                      gösteriyoruz. Kişisel Verilerin Korunması Kanunu’na (“KVKK”) tam uyumla tüm kişisel verilerinizi
                      koruma altına alıyoruz.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Web Sitemiz olan bayi.eksun.com.tr sitesine üye olurken yahut işbu Web Sitesi’ni kullanırken
                      tarafımıza işlenmesi için verdiğiniz kişisel verilerle ilgili olarak hazırlanmış Aydınlatma
                      Metnini dikkatinize sunarız.
                    </div>
                    <div class="text-body-1 font-weight-bold py-3">VERİ SORUMLUSUNUN KİMLİĞİ</div>
                    <div class="text-caption text-justify pb-3">
                      Bu Aydınlatma Metni, 6698 sayılı Kişisel Verilerin Korunması Kanununun 10. maddesi ile Aydınlatma
                      Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ kapsamında veri
                      sorumlusu sıfatıyla hazırlanmıştır.
                    </div>
                    <div><strong>Veri Sorumlusu :</strong> Eksun Gıda Tarım Sanayi ve Ticaret A.Ş.</div>
                    <div>
                      <strong>Adres :</strong> Altunizade Mh. Ord. Prof. Fahrettin Kerim Gökay Cd.No:36 Üsküdar/İstanbul
                    </div>

                    <div><strong>İrtibat Mail :</strong> kvk@eksun.com.tr</div>
                    <div class="text-body-1 font-weight-bold py-3">İŞLENEN KİŞİSEL VERİLER</div>
                    <div class="text-caption text-justify pb-3">
                      Kullanmakta olduğunuz Web Sitesi ile ilgili aşağıda sayılı olan kişisel verileriniz Veri
                      Sorumlusuna iletilmek suretiyle işlenmektedir.
                    </div>
                    <ul>
                      <li>Firma Ünvanı</li>
                      <li>Telefon\Mail Bilgileri,</li>
                      <li>T.C. Kimlik Numarası\Vergi Numarası,</li>
                      <li>Adresi\Adresleri,</li>
                      <li>Telefon Numarası,</li>
                      <li>Web sitesi kullanım hareketleri,</li>
                      <li>IP Adresi,</li>
                    </ul>

                    <div class="text-body-1 font-weight-bold py-3">KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI</div>
                    <div class="text-caption text-justify pb-3">
                      Toplanan kişisel verileriniz, Kanun’da öngörülen temel ilkelere uygun olarak ve Kanun’un 5. ve 6.
                      maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dâhilinde Şirket tarafından
                      aşağıda yer alan amaçlar kapsamında işlenebilecektir:
                    </div>
                    <ul>
                      <li>İletişim Faaliyetlerinin Yürütülmesi</li>
                      <li>İş Faaliyetlerinin Yürütülmesi / Denetimi</li>
                      <li>Mal / Hizmet Satış Süreçlerinin Yürütülmesi</li>
                      <li>Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi</li>
                      <li>Organizasyon ve Etkinlik Yönetimi</li>
                      <li>Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi</li>
                      <li>Yönetim Faaliyetlerinin Yürütülmesi</li>
                      <li>Pazarlama, reklam ve kampanya faaliyetlerinin yapılması,</li>
                      <li>Müşterilerin fırsatlardan, kampanyalardan ve sair hizmetlerden haberdar edilmesi.</li>
                    </ul>

                    <div class="text-caption text-justify py-3">
                      Yukarıdaki amaçlar kapsamında, Şirketimizin sunduğu ürün ve hizmetlerinden faydalanmayan ancak
                      ileride faydalanabilecek veri sahiplerinin kişisel verileri, müşteriler ve tedarikçilerle
                      temasların takibinin yapılması, potansiyel müşteri ve tedarikçiler ile yetkililerinin kayıtlarının
                      açılması, ürün ve hizmet üretim, satım ve satın alma süreçlerinin planlanması amacıyla
                      işlenebilecektir.
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Kişisel verilerinizin Şirketimiz tarafından işlenme amaçları konusunda detaylı bilgiler,
                      www.elsun.com.tr adresinden erişilebilen Eksun Gıda Tarım Sanayi ve Ticaret A.Ş. Kişisel Veri
                      Saklanması ve İmha Politikası’nda yer almaktadır.
                    </div>
                    <div class="text-body-1 font-weight-bold py-3">
                      KİŞİSEL VERİLERİNİZİN AKTARILDIĞI TARAFLAR VE AKTARIM AMAÇLARI
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Toplanan kişisel verileriniz, Kanun’da öngörülen temel ilkelere uygun olarak ve Kanun’un 8. ve 9.
                      maddelerinde belirtilen kişisel veri işleme şartları dâhilinde Şirketimiz tarafından yukarıda yer
                      alan amaçlar doğrultusunda;
                    </div>
                    <ul>
                      <li>Gerçek kişiler veya özel hukuk tüzel kişilere</li>
                      <li>Hissedarlara</li>
                      <li>İştirakler ve bağlı ortaklıklara</li>
                      <li>Tedarikçilere</li>
                      <li>Topluluk Şirketlerine</li>
                      <li>Yetkili Kamu Kurum ve Kuruluşlarına</li>
                      <li>Şirket çalışanlarına ve görevlilerine</li>
                      <li>Bağımsız Denetim Şirketlerine</li>
                      <li>İş Ortakları ve Hizmet Sağlayıcılarına</li>
                    </ul>

                    <div>aktarılabilecektir.</div>
                    <div class="text-body-1 font-weight-bold py-3">
                      KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Kişisel verileriniz Şirketimiz tarafından yukarıda sıralanan amaçlar kapsamında ve fiziki ortamda
                      sözleşmeler ve matbu formlar; elektronik ortamda ise e-mail, telefon, SMS, sosyal medya, kurumsal
                      web siteleri, üyelik oluşturma formları, çerezler ve pikseller gibi takip teknolojileri, pazarlama
                      otomasyonu, e-mail gönderim platformu, mobil uygulamalar, çağrı merkezleri, organizasyon
                      etkinlikleri, şikâyet yönetim sistemleri, görüntü ve ses kayıt sistemleri, Hybris Marketing, pazar
                      araştırma şirketleri ve referans yöntemleri üzerinden toplanacaktır. Toplanan kişisel verileriniz;
                      Kanun’un 5. maddesinde belirtilen açık rıza, sözleşmenin kurulması ve ifası, hukuki
                      yükümlülüğümüzün yerine getirilmesi ve meşru menfaate ulaşılması için zorunlu olması hukuki
                      sebeplerine dayalı olarak ve kişisel veri işleme şartları kapsamında işbu Aydınlatma Metninde
                      belirtilen amaçlarla işlenebilmekte ve aktarılabilmektedir.
                    </div>
                    <div class="text-body-1 font-weight-bold py-3">
                      5. KİŞİSEL VERİ SAHİBİ OLARAK KANUN’UN 11. MADDESİNDE SAYILAN HAKLARINIZ
                    </div>
                    <div class="text-caption text-justify pb-3">
                      Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu
                      bildiririz:
                    </div>
                    <ul>
                      <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
                      <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                      <li>
                        Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
                        öğrenme,
                      </li>
                      <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                      <li>
                        Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme
                        ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
                        isteme,
                      </li>
                      <li>
                        Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini
                        gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
                        isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere
                        bildirilmesini isteme,
                      </li>
                      <li>
                        İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize
                        bir sonucun ortaya çıkması durumunda buna itiraz etme,
                      </li>
                      <li>
                        Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın
                        giderilmesini talep etme.
                      </li>
                    </ul>

                    <div class="text-caption text-justify pb-3">
                      Yukarıda sıralanan haklarınıza yönelik başvurularınızı, bayi.eksun.com.tr/kvkbasvuruformu.pdf
                      adresinden ulaşabileceğiniz Kişisel Verilerin Korunması Başvuru Formu’nu doldurarak bizzat elden
                      iletme yahut noter kanalıyla veya KVK Kanunu’nda belirtilen diğer yöntemler ile iletme Şirketimize
                      iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en geç otuz gün içinde
                      başvurularınız ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi
                      halinde Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret
                      talep edilebilecektir.
                    </div>
                  </v-card-text>
                </perfect-scrollbar>
              </v-card>

              <v-card>
                <v-card-actions class="px-0 align-center">
                  <v-checkbox v-model="checkbox3" hide-details dense class="pa-0 ma-0">
                    <template v-slot:label>
                      <div class="text-caption">Kabul Ediyorum</div>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>

                  <v-btn color="secondary" :disabled="!checkbox3" depressed class="text-capitalize" @click="e6 = 4">
                    Devam
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="e6 !== 4" block color="secondary" @click="handleContractsSubmit"> Gönder </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import BackgroundPics from '@/components/BackgroundPics'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiCheckBold, mdiClose, mdiDraw, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { postData } from '@utils'
import { getCurrentInstance, ref } from '@vue/composition-api'
import Vue from 'vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  data() {
    return {
      slide: 0,
      slides: [
        { imgSrc: 'https://anlas.com/wp-content/uploads/2021/08/slide-bg-viento-sport.jpg' },
        { imgSrc: 'https://anlas.com/wp-content/uploads/2021/08/slide-bg-capra-rd.jpg' },
        { imgSrc: 'https://anlas.com/wp-content/uploads/2021/08/slide-bg-capra-r.jpg' },
        { imgSrc: 'https://anlas.com/wp-content/uploads/2021/08/slide-bg-capra-x-rally.jpg' },
        { imgSrc: 'https://anlas.com/wp-content/uploads/2021/08/slide-bg-tournee.jpg' },
        { imgSrc: 'https://anlas.com/wp-content/uploads/2021/08/slide-bg-winter-grip-plus.jpg' },

        // Diğer resimler...
      ],
    }
  },
  computed: {
    randomizedSlides() {
      // Resimleri rastgele sıralama
      return this.shuffleArray(this.slides)
    },
  },

  methods: {
    shuffleArray(array) {
      // Fisher-Yates algoritmasını kullanarak dizi elemanlarını rastgele sıralama
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    onSlideStart() {
      // Slide geçiş başlangıcında yapılacak işlemler
    },
    onSlideEnd() {
      // Slide geçiş sonunda yapılacak işlemler
    },
  },

  components: {
    // 3rd Party
    PerfectScrollbar,
    BackgroundPics,
  },
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const userData = ref(null)
    const loading = ref(false)
    const checkbox1 = ref(false)
    const checkbox2 = ref(false)
    const checkbox3 = ref(false)

    const dialogForm = ref(false)
    const { isDark } = useAppConfig()
    const e6 = ref(1)
    const vm = getCurrentInstance()?.proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const username = ref('')
    const password = ref('')

    // const username = ref('maverabilisim@gmail.com')
    // const password = ref('010203')

    // const username = ref('plasiyer')
    // const password = ref('010203')

    // const username = ref('MT0005471')
    // const password = ref('19821985')
    const perfectScrollbarOptions = {
      minScrollbarLength: 60,
      wheelPropagation: true,
    }
    const errorMessages = ref([])

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      postData({
        method: 'login',
        username: username.value,
        password: password.value,
      }).then(data => {
        if (data.error === 0) {
          if (
            data.detail.kullanici_uyelik_sozlesmesi === 0 &&
            data.detail.kullanici_uyelik_sozlesmesi === 0 &&
            data.detail.kvk_aydinlatma_metni === 0
          ) {
            const { accessToken } = data
            localStorage.setItem('accessToken', accessToken)
            userData.value = data
            handleContracts()
          } else {
            loading.value = false
            const { accessToken } = data
            localStorage.setItem('accessToken', accessToken)
            const { detail: user } = data
            const userAbility = data.ability

            vm.$ability.update(userAbility)
            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            delete user.ability
            localStorage.setItem('userData', JSON.stringify(user))
            router.push('/dashboard')
            setTimeout(() => {
              location.reload() // Reload the page after a brief delay
            }, 650)
          }
        } else {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: data.msg,
            icon: 'warning',
            background: '#FF4C51',
            confirmButtonColor: '#000',
          })
        }
      })
    }
    const handleContracts = () => {
      dialogForm.value = true
    }
    const handleContractsSubmit = async () => {
      postData({
        method: 'approveContract',
        kullanici_uyelik_sozlesmesi: 1,
        kvk_acik_riza_beyani: 1,
        kvk_aydinlatma_metni: 1,
      })
        .then(data => {
          if (data.error === 0) {
            const { detail: user } = userData.value
            const userAbility = userData.value.ability

            vm.$ability.update(userAbility)
            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            delete user.ability
            localStorage.setItem('userData', JSON.stringify(user))
            router.push('/dashboard')
          } else {
            Vue.swal({
              title: 'Hata',
              text: data.msg,
              icon: 'warning',
              background: '#FF4C51',
              confirmButtonColor: '#000',
            })
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
    const dialogClose = () => {
      loading.value = false
      e6.value = 1
      checkbox1.value = false
      checkbox2.value = false
      checkbox3.value = false
      dialogForm.value = false
    }

    return {
      handleFormSubmit,
      isPasswordVisible,
      username,
      isDark,
      password,
      loading,
      errorMessages,
      dialogForm,
      perfectScrollbarOptions,
      e6,
      checkbox1,
      checkbox2,
      checkbox3,
      handleContractsSubmit,
      dialogClose,
      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiDraw,
        mdiEyeOffOutline,
        mdiCheckBold,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
.overflow-contracts {
  overflow-y: scroll;
}
.ps-user-notifications {
  max-height: 300px;
  .ps__rail-y {
    display: block !important;
    opacity: 1 !important;
    .ps__thumb-y {
      opacity: 1 !important;
      background-color: #312d4b !important;
      width: 9px !important;
      height: 60px !important;
    }
  }
}
.div-3 {
  background-color: #fbd603;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  max-width: 100%; /* Added for responsiveness */
  width: 30%; /* Default width for larger screens */
  left: 30%;
}

@media (max-width: 1200px) {
  /* Styles for screens up to 1200px wide */
  .center {
    width: 400; /* Adjusted width for smaller screens */
  }
}

@media (max-width: 992px) {
  /* Styles for screens up to 992px wide */
  .center {
    width: 400; /* Further adjusted width for even smaller screens */
  }
}

@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  .center {
    width: 400px; /* Adjusted width for mobile screens */
    left: 50%; /* Resets the left position to center horizontally */
    transform: translateX(-50%); /* Removes vertical translation */
  }
}

.top-image {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 3920px;
  max-height: 110px;
  height: auto;
}

.custom-card-text {
  width: 100%;
}

.transparent {
  opacity: 0.9;
}
.grid-center {
  display: grid;
  place-items: center;
  padding-top: 50%;
}
</style>
